<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li :class="tab === 'content' ? 'active' : ''" @click="switchTab('content')">Content</li>
                <li :class="tab === 'delivered' ? 'active' : ''" @click="switchTab('delivered')">Sends <span>{{ (broadcast.sends &&  broadcast.unsubscribed) ? (broadcast.sends - broadcast.unsubscribed) : ( broadcast.sends ? broadcast.sends : 0 ) }}</span></li>
                <li :class="tab === 'opened' ? 'active' : ''" @click="switchTab('opened')">Opens <span>{{ broadcast.open_rate }}%</span></li>
                <li :class="tab === 'clicked' ? 'active' : ''" @click="switchTab('clicked')">Clicks <span>{{ broadcast.click_rate }}%</span></li>
                <li :class="tab === 'bounced' ? 'active' : ''" @click="switchTab('bounced')">Undelivered <span>{{ broadcast.undelivered_count ? broadcast.undelivered_count : 0 }}</span></li>
                <li :class="tab === 'unsubscribed' ? 'active' : ''" @click="switchTab('unsubscribed')">Unsubscribed <span>{{ broadcast.unsubscribed ? broadcast.unsubscribed : 0 }}</span></li>
                <li :class="tab === 'complained' ? 'active' : ''" @click="switchTab('complained')">Spam Complaint <span>{{ broadcast.complaint ? broadcast.complaint : 0 }}</span></li>
            </ul>
            <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleSectionBar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <form class="forms-setting-form">
                    <div class="step_wpr content_area">
                        <div class="content_wpr" v-show="tab === 'content'">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Statistics</h2>
                                </div>
                                <ul class="analytics">
                                    <li>
                                        <div class="analytics_card">
                                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                                <div class="inner_circle">
                                                    <div class="score">{{ (broadcast.sends ? ((broadcast.sends - broadcast.unsubscribed) * 100) / broadcast.sends : 0).toFixed() }}<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - ((broadcast.sends ? ((broadcast.sends - broadcast.unsubscribed) * 100) / broadcast.sends : 0) * 2.02)"/>
                                                </svg>
                                            </div>
                                            <h4>Sends</h4>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="analytics_card">
                                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                                <div class="inner_circle">
                                                    <div class="score">{{ broadcast.open_rate }}<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#F2A31D" :stroke-dasharray="202" :stroke-dashoffset="202 - (broadcast.open_rate * 2.02)"/>
                                                </svg>
                                            </div>
                                            <h4>Open Rate</h4>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="analytics_card">
                                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                                <div class="inner_circle">
                                                    <div class="score">{{ broadcast.click_rate }}<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#1df2ce" :stroke-dasharray="202" :stroke-dashoffset="202 - (broadcast.click_rate * 2.02)"/>
                                                </svg>
                                            </div>
                                            <h4>Click Rate</h4>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="analytics_card">
                                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                                <div class="inner_circle">
                                                    <div class="score">{{ 100 - (broadcast.sends && broadcast.undelivered_count ? ((broadcast.sends - broadcast.undelivered_count) * 100) / broadcast.sends : 100).toFixed() }}<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#ff7f7f" :stroke-dasharray="202" :stroke-dashoffset="202 - ((100 - (broadcast.sends && broadcast.undelivered_count ? ((broadcast.sends - broadcast.undelivered_count) * 100) / broadcast.sends : 100).toFixed()) * 2)" />
                                                </svg>
                                            </div>
                                            <h4>Undelivered</h4>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="analytics_card">
                                            <div class="score_circle" style="width:68px; height:68px; padding: 4px;">
                                                <div class="inner_circle">
                                                    <div class="score">{{ 100 - (broadcast.sends && broadcast.unsubscribed ? ((broadcast.sends - broadcast.unsubscribed) * 100) / broadcast.sends : 100).toFixed() }}<span>%</span></div>
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="68" height="68">
                                                    <circle cx="34" cy="34" r="32" stroke-linecap="round" fill="none" stroke-width="4px" stroke="#9a1df2" :stroke-dasharray="202" :stroke-dashoffset="202 - ((100 - (broadcast.sends && broadcast.unsubscribed ? ((broadcast.sends - broadcast.unsubscribed) * 100) / broadcast.sends : 100).toFixed()) * 2)" />
                                                </svg>
                                            </div>
                                            <h4>Unsubscribed</h4>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Content</h2>
                                </div>
                                <div class="setting_wpr">
                                    <div class="msg_preview">
                                        <p><span>Subject: </span>{{ replaceVariables(broadcast.subject) }}</p>
                                        <p><span>Content: </span></p>
                                        <div class="email-logo" v-if="broadcast.show_email_logo">
                                            <img :src="broadcast.email_logo ? broadcast.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                                        </div>
                                        <div class="redactor-styles redactor-in redactor-in-0 p-0 content-prefix" v-html="replaceVariables(broadcast.content)"></div>
                                        <div v-html="generateSignature(broadcast.is_signature, broadcast.signature_id)"></div>
                                    </div>
                                    <div class="footer_text">
                                        <p>
                                            <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                                <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                                <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                            </template>
                                            <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                                        </p>
                                        <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                                        <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-show="tab !== 'content'">
                            <broadcast-logs :title="title" :type="type" :count="count" :broadcast="broadcast" />
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true" v-if="tab == 'content'">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button type="button" class="btn cancel_btn" @click="close">Cancel</button>
                        <button type="button" class="btn save_btn" @click="handleResendUnopens()"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Creating' : 'Resend Unopens' }}</button>
                    </div>
                </form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab === 'content'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="broadcast_preview_msg" class="switch_option capsule_btn p-0">
                            <h5 class="mr-3">Preview</h5>
                            <input type="checkbox" id="broadcast_preview_msg" v-model="previewContent" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(broadcast.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                    <p>{{ moment(broadcast.schedule_datetime).local().format('MMM DD, YYYY | hh:mm A') +' (' + broadcast.formatted_tz + ')' }}</p>
                                </div>
                            </div>
                            <div class="email-logo" v-if="broadcast.show_email_logo">
                                <img :src="broadcast.email_logo ? broadcast.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(broadcast.content)"></div>
                            <div v-html="generateSignature(broadcast.is_signature, broadcast.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mt-2 mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <preview-content v-model="previewContent" :broadcast="broadcast" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapGetters, mapActions } from 'vuex'

    const PreviewContent = defineAsyncComponent(() => import('@/pages/broadcast/components/PreviewContent'))
    const BroadcastLogs = defineAsyncComponent(() => import('@/pages/broadcast/components/BroadcastLogs'))

    import moment from 'moment'

    export default {
        name: 'Broadcast Completed',

        data () {
            return {
                tab: 'content',
                preview: true,
                previewContent: false,
                whiteLabel: {},
                env: {},
                moment,
                broadcastLogs: false,
                title: '',
                type: '',
                count: 0,
            };
        },

        props: {
            modelValue: Boolean,
            broadcast: Object,
            refreshBroadcast: {
                type: Function,
                default: () => {},
            },
        },

        emits: ['update:modelValue'],

        components: {
            PreviewContent,
            BroadcastLogs,
        },

        watch: {
            modelValue () {
                const vm = this;

                vm.tab  = 'content';
                vm.type = 'content';
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                loader: state => state.broadcastModule.broadcastComponentLoader,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.env = process.env;

            window.addEventListener("resize", vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted() {
            const vm = this;

            window.removeEventListener("resize", vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                resendUnopens: 'broadcastModule/resendUnopens',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            switchTab (tab) {
                const vm = this;

                if (tab != 'content') {
                    if (tab == 'delivered')  {
                        vm.count = (vm.broadcast.sends &&  vm.broadcast.unsubscribed) ? (vm.broadcast.sends - vm.broadcast.unsubscribed) : ( vm.broadcast.sends ? vm.broadcast.sends : 0 );
                        vm.title = 'Recipient';
                    } else if (tab == 'opened') {
                        vm.count = vm.broadcast.open_rate;
                        vm.title = 'Open Rate';
                    } else if (tab == 'clicked') {
                        vm.count = vm.broadcast.click_rate;
                        vm.title = 'Click Rate';
                    } else if (tab == 'unsubscribed') {
                        vm.count = vm.broadcast.unsubscribed ? vm.broadcast.unsubscribed : 0;
                        vm.title = 'Unsubscribes';
                    } else if (tab == 'bounced') {
                        vm.count = vm.broadcast.undelivered_count ? vm.broadcast.undelivered_count : 0;
                        vm.title = 'Undelivered';
                    } else if (tab == 'complained') {
                        vm.count = vm.broadcast.complaint ? vm.broadcast.complaint : 0;
                        vm.title = 'Spam Complaint';
                    }
                }
                vm.type = tab;
                vm.tab  = tab;
            },

            handleResendUnopens () {
                const vm = this;

                vm.resendUnopens({ broadcast_id: vm.broadcast.id }).then((result) => {
                    if (result) {
                        vm.refreshBroadcast();
                        vm.closeModal();
                    }
                })
            },

            toggleSectionBar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    this.preview = false;
                } else {
                    this.preview = true;
                }
            },
        },
    }
</script>

<style scoped>
    .forms-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 4;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .tabs_btn li {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .tabs_btn li span {
        padding: 2px 5px;
        background: #2f7eed;
        border-radius: 10px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #fff;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        border: 1px solid #fafafb;
        min-width: 24px;
        text-align: center;
    }

    .global_setting .profile_modal .close_btn {
        left: auto;
    }

    .section_header h2 {
        display: flex;
        align-items: flex-end;
    }

    .section_header h2 span {
        padding: 3px 8px;
        background: #f2a31d;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #fff;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        margin: 0 0 5px 10px;
    }

    .msg_preview {
        padding: 25px 30px;
        background: #fff;
        /* border: 1px solid #eaeaea; */
        margin: 15px 0;
        border-radius: 5px;
    }

    .msg_preview p {
        font-size: 14px;
        line-height: 22px;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 15px;
    }

    .msg_preview p span {
        font-size: 16px;
        line-height: 23px;
        display: block;
        padding-bottom: 3px;
        font-weight: 500;
        color: #121525;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        margin: 15px;
        background: #fff;
        border: 1px solid #e7e7e7;
        position: relative;
        border-radius: 10px;
    }

    .cell .msg_preview:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        display: inline-block;
    }

    .cell .msgFooter :deep(p) {
        font-size: 11px;
        line-height: 17px;
    }

    .cell .msgFooter :deep(p a) {
        text-decoration: none;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 15px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .sender_info .info p {
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        color: #999;
    }

    .analytics {
        display: flex;
        flex-wrap: wrap;
        margin: 5px -10px 0 -10px;
    }

    .analytics li {
        padding: 10px;
        width: 33.333%;
    }

    .analytics_card {
        /* border: 1px solid #eaeaea; */
        background: #fff;
        border-radius: 8px;
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    .analytics_card h4 {
        font-size: 15px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
        margin-top: 10px;
    }

    .analytics_card:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 5px;
        font-size: 14px;
    }

    .redactor-styles.redactor-in p {
        font-size: 14px;
        line-height: 22px;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 15px;
    }

    .redactor-styles.content-prefix :deep(h2){
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 28px;
    }
    .redactor-styles.content-prefix :deep(p){
        margin-bottom: 15px;
        font-size: 14px !important;
        line-height: 22px;
    }
    .redactor-styles.content-prefix :deep(ul){
        margin-left: 25px;
    }
    .redactor-styles.content-prefix :deep(ul li){
        padding: 8px 0;
        font-size: 14px;
        line-height: 22px;
    }
    @media(max-width: 599px){
        .redactor-styles.content-prefix :deep(h2){
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 25px
        }
        .redactor-styles.content-prefix :deep(p){
            margin-bottom: 12px;
            font-size: 13px !important;
            line-height: 18px;
        }
        .redactor-styles.content-prefix :deep(ul){
            margin-left: 20px;
        }
        .redactor-styles.content-prefix :deep(ul li){
            padding: 6px 0;
            font-size: 13px;
            line-height: 18px;
        }
    }
    @media(max-width: 420px){
        .msg_preview{
            padding: 30px 20px;
        }
        .redactor-styles.content-prefix :deep(h2){
            margin-bottom: 12px;
            font-size: 15px;
            line-height: 22px
        }
        .redactor-styles.content-prefix :deep(p){
            margin-bottom: 10px;
            font-size: 11px !important;
            line-height: 16px;
        }
        .redactor-styles.content-prefix :deep(ul){
            margin-left: 20px;
        }
        .redactor-styles.content-prefix :deep(ul li){
            padding: 4px 0;
            font-size: 11px;
            line-height: 16px;
        }
    }
    .footer_text{
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        text-align: center;
        padding-top: 10px;
    }
    .footer_text p i.fa-bolt {
        color: #FF9041;
        margin-right: 5px;
    }
    .footer_text a{
        text-decoration: none;
    }
</style>
